import React from "react"
import { FormattedMessage } from "react-intl"
import { Col, Container, Row } from "reactstrap"

const HtgfHero: React.FC = () => (
  <section className="banner mb-6 d-flex flex-column justify-content-center">
    <Container>
      <Row>
        <Col xs={12} lg={7}>
          <h1 className="text-white display-4">
            <FormattedMessage
              id="htgf.hero.header"
              defaultMessage="Apply now to opportunities in our network of innovative portfolio companies."
            />
          </h1>
          <div className="text-white h5">
            <FormattedMessage
              id="htgf.hero.body"
              defaultMessage="Simply upload your resume to get started."
            />
          </div>
        </Col>
      </Row>
    </Container>
  </section>
)

export default HtgfHero
